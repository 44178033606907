<template>
  <v-card-text class="pt-2 max-width">
    <h4 class="text-h6 mb-1 text-md-h5">
      Willkommen bei TerminTiger! 👋🏻
    </h4>
    <p class="mb-0">
      Bitte geben Sie Ihre Daten ein, um fortzufahren.
    </p>
  </v-card-text>

  <v-card-text class="max-width">
    <VForm @submit.prevent="register">
      <v-row>
        <!-- name -->
        <v-col cols="12">
          <VTextField
            v-model="form.name"
            label="Vollständiger Name *"
            required
            type="text"
          />
        </v-col>

        <!-- phone -->
        <v-col cols="12">
          <VTextField
            v-model="form.phone"
            label="Telefonnummer"
            type="text"
          />
        </v-col>

        <!-- email -->
        <v-col cols="12">
          <VTextField
            v-model="form.email"
            label="E-Mail-Adresse *"
            type="email"
            required
          />
        </v-col>

        <!-- password -->
        <v-col cols="12">
          <PasswordField
            v-model="form.password"
            label="Passwort *"
            required
          />
        </v-col>

        <!-- confirm password -->
        <v-col cols="12">
          <PasswordField
            v-model="form.confirmPassword"
            label="Passwort bestätigen *"
            required
          />

          <v-alert
            v-if="passwordMismatch"
            type="error"
            class="mt-2"
          >
            Passwörter stimmen nicht überein
          </v-alert>
        </v-col>

        <v-col cols="12">
          <p class="text-caption mb-2">* Pflichtfelder</p>
        </v-col>

        <!-- register button -->
        <v-col cols="12">
          <VBtn
            block
            type="submit"
            :loading="isLoading"
          >
            Registrieren
          </VBtn>
        </v-col>

        <v-col cols="12">
          <v-alert
            v-if="showError"
            type="error"
            class="mt-2"
          >
            {{ errorMessage }}
          </v-alert>
        </v-col>

        <!-- login link -->
        <v-col
          cols="12"
          class="text-center text-base"
        >
          <span>Haben Sie bereits ein Konto?</span>
          <NuxtLink
            class="text-primary ms-2"
            to="/auth/login"
          >
            Stattdessen anmelden
          </NuxtLink>
        </v-col>
      </v-row>
    </VForm>
  </v-card-text>
</template>

<script lang="ts">
import Logo from '~/components/Logo.vue';
import PasswordField from '~/components/PasswordField.vue';

definePageMeta({
  layout: 'default-auth'
});

export default defineNuxtComponent({
  setup() {
    const isLoading = ref(false)
    const showError = ref(false)
    const errorMessage = ref('')
    const form = ref({
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
    })

    const passwordMismatch = ref(false)

    const validatePasswords = () => {
      passwordMismatch.value = form.value.password !== form.value.confirmPassword
    }

    watch(() => form.value.confirmPassword, validatePasswords)
    watch(() => form.value.password, validatePasswords)

    const register = async () => {
      errorMessage.value = ''
      showError.value = false

      if (passwordMismatch.value) {
        return
      }

      // Check for required fields
      if (!form.value.name || !form.value.email || !form.value.password || !form.value.confirmPassword) {
        errorMessage.value = 'Bitte füllen Sie alle Felder aus.'
        showError.value = true
        return
      }

      // Basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(form.value.email)) {
        errorMessage.value = 'Bitte geben Sie eine gültige E-Mail-Adresse ein.'
        showError.value = true
        return
      }

      // Password strength check (example: at least 8 characters)
      if (form.value.password.length < 8) {
        errorMessage.value = 'Das Passwort muss mindestens 8 Zeichen lang sein.'
        showError.value = true
        return
      }

      try {
        isLoading.value = true

        const data = await $fetch('/api/auth/register-customer', {
          method: 'POST',
          body: form.value
        })

        if (data.success) {
          navigateTo('/auth/email-confirmation')
        } else {
          showError.value = true
          errorMessage.value = 'Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut. Wenn das Problem bestehen sollte, wenden Sie sich bitte an den Support.'
        }
      } catch (error) {
        console.error(error)
        showError.value = true
        errorMessage.value = 'Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut. Wenn das Problem bestehen sollte, wenden Sie sich bitte an den Support.'
      }

      isLoading.value = false
    }

    return {
      form,
      register,
      passwordMismatch,
      isLoading,
      showError,
      errorMessage
    }
  },
  components: { Logo, PasswordField }
})
</script>

<style scoped lang="scss">
.max-width {
  max-width: 650px;
}
</style>