<template>
  <v-card-text>
    <h4 class="text-h6 mb-1 text-md-h5">
      Willkommen bei TerminTiger! 👋🏻
    </h4>
    <p class="mb-0">
      Bitte geben Sie Ihre Anmeldeinformationen ein, um fortzufahren.
    </p>
  </v-card-text>

  <v-card-text>
    <VForm @submit.prevent="login">
      <v-row>
        <!-- email -->
        <v-col cols="12">
          <VTextField
            v-model="form.email"
            label="E-Mail-Adresse"
            type="email"
            required
          />
        </v-col>

        <!-- password -->
        <v-col cols="12">
          <PasswordField v-model="form.password" />

          <!-- remember me checkbox -->
          <div class="d-flex align-center justify-space-between flex-wrap my-6">
            <NuxtLink
              to="/auth/recovery"
              class="text-primary"
            >
              Passwort vergessen?
            </NuxtLink>
          </div>

          <v-alert
            text="Falsche E-Mail-Adresse oder Passwort"
            class="mb-5"
            type="error"
            v-if="showError"
          ></v-alert>
          <!-- login button -->
          <Button
            block
            type="submit"
          >
            Login
          </Button>
        </v-col>

        <!-- create account -->
        <v-col
          cols="12"
          class="text-center text-base"
        >
          <span>Noch kein Konto?</span>
          <RouterLink
            class="text-primary ms-2"
            to="/auth/register"
          >
            Konto erstellen
          </RouterLink>
          <!-- <v-divider class="my-2" />
          Sie sind Geschäftskunde? 
          <RouterLink
            class="text-primary ms-2"
            to="/auth/login-admin"
          >
            Geschäftskonto Login
          </RouterLink> -->
        </v-col>
      </v-row>
    </VForm>
  </v-card-text>
</template>

<script lang="ts">
import PasswordField from '~/components/PasswordField.vue';

definePageMeta({
  layout: 'default-auth'
});

export default defineNuxtComponent({
  setup() {
    const supabase = useSupabaseClient();
    const form = ref({
      email: "",
      password: ""
    });
    const isPasswordVisible = ref(false);
    const showError = ref(false);
    const login = async () => {
      if (!form.value.email || !form.value.password) {
        return false;
      }
      const { data, error } = await supabase.auth.signInWithPassword({
        email: form.value.email,
        password: form.value.password
      });

      if (!error) {
        return navigateTo("/auth/confirm");
      }
      else {
        showError.value = true;
      }
      return false;
    };
    return {
      form,
      login,
      showError,
      isPasswordVisible
    };
  },
  components: { PasswordField }
})
</script>
